import http from "./index";


const getAPISubscriptions  = () => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/tnapi/subscription/getbyuserid?userid=${window.Gemini.UserData.userID}`)
           .then(res => {
            if(res != null & res.data != null && res.data.appCode === "SUCCESS")
            {
                return res.data.data
            }else{
                console.log("Error while retrieving subscriptions =>" + res.data)
                return null;
            }
        })
        .catch(err =>{
            console.log("Error while retrieving subscriptions =>" + err);
            return null;
            }
        )
}

const getApiStatusLookup  = () => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/tnapi/lookup/status`)
           .then(res => {
            if(res != null & res.data != null && res.data.appCode === "SUCCESS")
            {
                return res.data.data
            }else{
                console.log("Error while retrieving subscriptions status =>" + res.data)
                return null;
            }
        })
        .catch(err =>{
            console.log("Error while retrieving subscriptions status =>" + err);
            return null;
            }
        )
}

const getApiQueryRange  = () => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/tnapi/lookup/queryrange`)
           .then(res => {
            if(res != null & res.data != null && res.data.appCode === "SUCCESS")
            {
                return res.data.data
            }else{
                console.log("Error while retrieving subscriptions suites =>" + res.data)
                return null;
            }
        })
        .catch(err =>{
            console.log("Error while retrieving subscriptions  =>" + err);
            return null;
            }
        )
}

const getApiSuiteLookup  = () => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/tnapi/lookup/apisuite`)
           .then(res => {
            if(res != null & res.data != null && res.data.appCode === "SUCCESS")
            {
                return res.data.data
            }else{
                console.log("Error while retrieving subscriptions suites =>" + res.data)
                return null;
            }
        })
        .catch(err =>{
            console.log("Error while retrieving subscriptions  =>" + err);
            return null;
            }
        )
}

const getApiTypeLookup  = () => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/tnapi/lookup/type`)
           .then(res => {
            if(res != null & res.data != null && res.data.appCode === "SUCCESS")
            {
                return res.data.data
            }else{
                console.log("Error while retrieving subscriptions typr =>" + res.data)
                return null;
            }
        })
        .catch(err =>{
            console.log("Error while retrieving subscriptions type =>" + err);
            return null;
            }
        )
}

const getSubscriptionDetail  = (key) => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/tnapi/subscription/${key}`)
           .then(res => {
            if(res != null & res.data != null && res.data.appCode === "SUCCESS")
            {
                return res.data.data
            }else{
                console.log("Error while retrieving subscriptions detail =>" + res.data)
                return null;
            }
        })
        .catch(err =>{
            console.log("Error while retrieving subscriptions detail =>" + err);
            return null;
            }
        )
}

const getApiSuiteUsecases = (id) => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/tnapi/lookup/apisuite/${id}/usecase`)
           .then(res => {
            if(res != null & res.data != null && res.data.appCode === "SUCCESS")
            {
                return res.data.data
            }else{
                console.log("Error while retrieving subscriptions detail =>" + res.data)
                return null;
            }
        })
        .catch(err =>{
            console.log("Error while retrieving subscriptions detail =>" + err);
            return null;
            }
        )
}

const getConsumedCreditsOfUser = (key) => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/tnapi/subscription/${key}/consumedcredits?userid=${window.Gemini.UserData.userID}`)
           .then(res => {
            if(res != null & res.data != null && res.data.appCode === "SUCCESS")
            {
                return res.data.data
            }else{
                console.log("Error while retrieving subscriptions detail =>" + res.data)
                return null;
            }
        })
        .catch(err =>{
            console.log("Error while retrieving subscriptions detail =>" + err);
            return null;
            }
        )
}

const getConsumedCreditsOfClient = (key) => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/tnapi/subscription/${key}/consumedcredits`)
           .then(res => {
            if(res != null & res.data != null && res.data.appCode === "SUCCESS")
            {
                return res.data.data
            }else{
                console.log("Error while retrieving subscriptions detail =>" + res.data)
                return null;
            }
        })
        .catch(err =>{
            console.log("Error while retrieving subscriptions detail =>" + err);
            return null;
            }
        )
}

const getTNPlatformCountries = (key) => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/v1/cms/tn/countrylist`)
           .then(res => {
            if(res != null & res.data.isError == false && res.data.message === "SUCCESS")
            {
                return res.data.data
            }else{
                console.log("Error while retrieving tn countries =>" + res.data)
                return null;
            }
        })
        .catch(err =>{
            console.log("Error while retrieving tn countries =>" + err);
            return null;
            }
        )
}

const sendEmailForSubscriberCredentials = (key,name) => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/tnapi/notify/secret/${key}/${name}`)
           .then(res => {
            return true;
        })
        .catch(err =>{
            console.log("Error while notifying subscription secret =>" + err);
            return false;
        }
    )
}

export default {getAPISubscriptions,getApiQueryRange,getApiStatusLookup,getApiSuiteLookup,getApiTypeLookup,getSubscriptionDetail,getApiSuiteUsecases,getConsumedCreditsOfUser,getConsumedCreditsOfClient,getTNPlatformCountries,sendEmailForSubscriberCredentials}

