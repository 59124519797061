import React, { Component } from 'react';
import { onlyStaffingEnabled } from '../../utils';
import {
    HashRouter,
    Switch,
    Route, BrowserRouter  
} from "react-router-dom";

import Header from './Header';
import { Footer } from 'tn-components-library';
import Home from '../home';
import { footerUrls } from "../../utils";
import Account from "../myaccount";
import '../../styles.css'
import TNApiIntermediatePage from '../TNApiIntermediatePage';
import { getRolesFromProductDetails } from '../../utils';

class Root extends Component {

    componentDidMount(){
        if(window.Gemini && window.Gemini.UserData && window.Gemini.UserData.ishotjarenabled){
            (function(h,o,t,j,i,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:i,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=', window.Gemini.Config.trackingHotJarHomePageId);
        }
    }   

    render() {
        const userDetails = {
            CustomRoleEnabled: window.Gemini.UserData.customRoleEnabled,
            AdminEnabled: window.Gemini.UserData.adminEnabled,
            Tnr1xEnabled: window.Gemini.UserData.WA,
            Tnr2xEnabled: window.Gemini.UserData.GEMINI,
            Tnp1XEnabled : window.Gemini.UserData.TN,
            plan: window.Gemini.UserData.PLAN2x,
            DataFeedEnabled: window.Gemini.UserData.dataFeedEnabled,
            StaffingEnabled : window.Gemini.UserData.staffingEnabled
        }
        onlyStaffingEnabled(userDetails)   
        return (
                <BrowserRouter basename={"/home"} >
                    <Header/>
                    <Switch>
                        <Route exact={true} path="/myaccount">
                            <Account/>
                        </Route>
                        <Route exact={true} path={"/"}>
                            <Home/>
                        </Route>
                        <Route exact={true} path={"/requestapiaccess"}>
                            <TNApiIntermediatePage/>
                        </Route>
                    </Switch>
                    <Footer 
                        roles={getRolesFromProductDetails(window.Gemini?.UserData?.productDetails)}  
                        plan={userDetails.plan}
                        recruit={userDetails.Tnr2xEnabled}
                        customRole={userDetails.CustomRoleEnabled} 
                        pageUrl={footerUrls} 
                        staffing={window.Gemini.UserData.staffingEnabled}
                    />
                    
                </BrowserRouter>
        );
    }
}

export default Root;
