import Cookies from 'js-cookie'

export const addEllipsis = (name, n) => {
	if (!name || name.length == 0) return '';

	name = (name.length > n) ? (name.substring(0, n) + '...') : name;
	return name;
}

if (!Element.prototype.matches) {
	Element.prototype.matches =
		Element.prototype.matchesSelector ||
		Element.prototype.mozMatchesSelector ||
		Element.prototype.msMatchesSelector ||
		Element.prototype.oMatchesSelector ||
		Element.prototype.webkitMatchesSelector ||
		function (s) {
			var matches = (this.document || this.ownerDocument).querySelectorAll(s),
				i = matches.length;
			while (--i >= 0 && matches.item(i) !== this) { }
			return i > -1;
		};
}

export const savingFileFromB64 = (fileContent,contentType,fileName) =>{
	let fileNameWithExtn = `${fileName}.${contentType.split('/')[1]}`;
	let binaryString = window.atob(fileContent);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
	let blob = new Blob([bytes], {type: contentType});
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob, fileNameWithExtn);
	}else{
		let link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileNameWithExtn;
		if(navigator.userAgent.search("Firefox") > -1){
			document.body.appendChild(link);
		}
		link.click();
		if(navigator.userAgent.search("Firefox") > -1){
			document.body.removeChild(link);
		}
	}
}

export const getCountryByCode = (code) => {
	let allCountries = [...window.Gemini.UserData.subscribedCountries, ...window.Gemini.UserData.unSubscribedCountries];
	return allCountries.filter(country => country.code == code)[0];
}

export const convertUTCDateToLocalDate = (date) => {
	let utcDate = new Date(date)
	let localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset()*60*1000);
	let offset = utcDate.getTimezoneOffset() / 60;
	let hours = utcDate.getHours();
	localDate.setHours(hours - offset);
	return localDate;
}

export const convertLongtoDate = (long)=>{
	let dateStr = new Date(long), dd=dateStr.getDate(), mm=dateStr.getMonth() + 1, yyyy=dateStr.getFullYear(), hr=dateStr.getHours(), min=dateStr.getMinutes(), ampm= "AM";
	const date = getMonthByNumber(dateStr.getMonth() ) + " "  + dateStr.getDate() + ", " + dateStr.getFullYear()


	min = min < 10? "0" + min : min;
	hr = (hr==0)?12:hr;
	ampm = (hr>=12)?'PM': ampm;
	hr = (hr>12)?(hr%12):hr;
	return  date + " " +  " " + hr + ':' + min + '' + ampm;

}

export const getMonthByNumber = (num) => {
	return ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][num]
}

export const detectIE = ()=> {
    var ua = window.navigator.userAgent;
    var ie = ua.search(/(MSIE|Trident|Edge)/);

    return ie > -1;
}

export const encodeQuotes = (params)=> {
    if(detectIE()){
        let newParams = [];
        params.split('').forEach((elem,i)=>{
                if(elem.indexOf("\"") == 0 || elem.indexOf("\'") == 0) {
                    newParams.push(encodeURIComponent(elem));  
                }else{
                    newParams.push(elem);
                }
            });
        return newParams.join('');
    }else{
        return params;
    }
}
export const AppUrls = {
	TnpURL: window.Gemini.Config.legacyPlanURL,
	AdminURL: window.Gemini.Config.adminURL,
	Tnr1xURL: window.Gemini.Config.legacyRecuritUrl,
	Tnr2xURL: window.Gemini.Config.recuritURL,
	HelpCentreURL: window.Gemini.Config.helpCenterURL,
	HomeURL: window.Gemini.Config.homeURL,
	CustomRoleURL: window.Gemini.Config.customRoleURL,
	DataFeedURL: window.Gemini.Config.dataFeedURL,
	PLAN2x: window.Gemini.Config.plan2xURL,
	StaffingURL:window.Gemini.Config.staffingURL,
	TrainingURL: ""
}

export const onlyStaffingEnabled = function(userDetails) {
	const{ StaffingEnabled ,Tnr2xEnabled ,plan , CustomRoleEnabled, DataFeedEnabled} =  userDetails
	const myaccountAccess = window.location.href.includes('myaccount') || window.location.href.includes('changePassword')
	if (!plan && !Tnr2xEnabled && !CustomRoleEnabled && !DataFeedEnabled && StaffingEnabled && !myaccountAccess) {
		window.location.href =  AppUrls.StaffingURL;
	  }   
  }

export const footerUrls = {
		ourData: `${window.Gemini.Config.homeURL}/learnmore/ourdata`,
		countries: `${window.Gemini.Config.homeURL}/learnmore/countries`,
		productUpdates: `${window.Gemini.Config.homeURL}/learnmore/productupdates`,
		caseStudies: `${window.Gemini.Config.homeURL}/learnmore/casestudies`,
		insights: `${window.Gemini.Config.homeURL}/learnmore/insights`,
		plan: `${window.Gemini.Config.plan2xURL}/#`,
		recruit: window.Gemini.Config.recuritURL,
		customRoles: window.Gemini.Config.customRoleURL,
		helpCenter: window.Gemini.Config.helpCenterURL,
		staffing: window.Gemini.Config.staffingURL,
		contactUs: 'mailto:support@talentneuron.com',
		termsOfService: 'https://www.talentneuron.com/terms-and-conditions/',
		privacyStatement: 'https://www.talentneuron.com/privacy-policy/'
}

export const formatDateForSavedSearches = (timestamp) => {
	const date = new Date(timestamp);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');
	const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export const hasProductAccess = (productList,productName) => {
	return productList.filter( product => product.productName === productName).length > 0;
}

export const getFormattedDate = (date) => {
    let dateArr = date != null ? date.split('/') : [];
    let formattedDate = new Date(dateArr[2],parseInt(dateArr[1]) - 1,dateArr[0]);  // 2009-11-10
    let formattedMonth = formattedDate.toLocaleString('default', { month: 'short' });
    return `${dateArr[0]} ${formattedMonth} ${dateArr[2]}`;
  }

export const TOKEN_REFRESH_TIME = 1750000
export const TOKEN_REFRESH_THRESHOLD_TIME = 70

export const getModuleNames = (planModules) => {
	const modules = []
	for (const [module, moduleDetails] of Object.entries(planModules)) {
	  if (
		Object.keys((moduleDetails).widgets).length > 0 &&
		Object.keys((moduleDetails).pages).length > 0
	  ) {
		modules.push(module)
	  }
	}
	return modules
  }

export const getRolesFromProductDetails = (productDetails = []) => {
	let roles = []
	productDetails.forEach((product) => {
	  if (product.pageDetails) {
		const pageData = product.pageDetails.map((role) => {
		  return {
			id: role.pageId,
			pageName: role.pageName,
			pageCode: role.code,
			isEnabled: role.isEnabled,
			pageProductName: role.pageProductName,
			permissionDetails: role.permissionDetails || [],
		  }
		})
		roles = roles.concat(pageData)
	  }
	})
	return roles
}

export const MODULARIZATION_WALKTHROUGH_VIDEO_URL= 'https://recruit.talentneuron.com/videos/tn-homepage-update.mp4'

export const getCookieDomainInfo = () => {
	const domain = window.location.hostname.split('.').slice(-2).join('.')
	return '.' + domain
}

export const getCookieValue = (cookieName) => {
	return Cookies.get(cookieName, {
		domain: getCookieDomainInfo(),
	})
}